import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Logo from "./logo";
import Menu from "./menu";
import Products from "./products";
//
export default ({ description, footer, ...rest }) => (
  <Wrap>
    <Logo {...rest} />
    <Menu {...rest} />
    <ItemHolder>
      <Item>{description && <RichText {...description} />}</Item>
    </ItemHolder>
    <Products {...rest} />
    <Footer>{footer && <RichText {...footer} />}</Footer>
    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1000px;

  p {
    max-width: 600px;
  }
`;
const ItemHolder = styled.div`
  padding-bottom: 0.5rem;
  @media (min-width: 769px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 250px auto;
    padding-bottom: 2rem;
  }
`;
const Item = styled.div`
  padding: 1rem 0.5rem;

  @media (min-width: 769px) {
    grid-column: 2;
  }
`;
const Footer = styled.div`
  margin: 0 auto;
  padding: 4rem 1rem 2rem;
  text-align: center;
  h2 {
    margin: 1rem 0 1.7rem;
  }
  p {
    margin: 0.5rem;
  }
  a:hover {
    font-weight: 600;
  }

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 250px;
  }

  @media (min-width: 769px) {
    padding: 2rem 1rem;
  }
`;
