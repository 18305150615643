import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ items, ...rest }) => (
  <Wrap>
    {items.map((item, i) => (
      <ProductItem key={i} id={item.item_title}>
        <img src={item.item_image.url} alt={item.item_description} />
        <Textholder>
          <Text>
            <Title>{item.item_title}</Title>
            <Description>
              <RichText {...item.item_description} />
            </Description>
          </Text>
        </Textholder>
      </ProductItem>
    ))}
  </Wrap>
);
//
const Wrap = styled.div``;
const ProductItem = styled.div`
  padding-top: 70px;
  a {
    cursor: pointer;
  }

  a:hover {
    font-weight: bold;
  }

  @media (min-width: 769px) {
    padding: 0 0 2rem 0;
  }
`;
const Textholder = styled.div`
  @media (min-width: 769px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 250px auto;
  }
`;
const Text = styled.div`
  grid-column: 2;
  justify-self: start;
  max-width: 1000px;
  padding: 0.5rem;

  p {
    margin: 0.5rem 0;
  }

  @media (min-width: 769px) {
    padding: 1rem 0 3rem 1rem;
  }
`;
const Title = styled.h2`
  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    margin: 0.5rem 0
    width: 4rem;
  }
`;
const Description = styled.div`
  padding: 0.5rem 0;
`;
