import React from "react";
import styled from "styled-components";
//
export default ({ image, ...rest }) => (
  <Wrap>
    <img id="top" src={image.url} alt={image.alt} />
  </Wrap>
);
//
const Wrap = styled.div``;
