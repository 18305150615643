import React from "react";
import styled from "styled-components";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
//
export default ({ title, items, ...rest }) => (
  <Wrap>
    <Link activeClass="active" to="top">
      <Title>{title}</Title>
    </Link>
    <Menu>
      {items.map((item, i) => (
        <MenuItem key={i}>
          <Link
            key={i}
            activeClass="active"
            to={item.item_title}
            spy={true}
            offset={-50}
          >
            {item.item_title}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  </Wrap>
);
//

const Wrap = styled.div`
  background-color: #ffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 102;

  @media (min-width: 769px) {
    background-color: transparent;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 250px auto;
  }
`;
const Title = styled.h1`
  cursor: pointer;
  min-height: 3rem;
  min-height: calc(3rem + 2px);
  margin: 0 auto;
  text-align: center;
  line-height: 3rem;
  line-height: calc(3rem + 2px);
  white-space: nowrap;
`;
const Menu = styled.nav`
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: start;
`;
const MenuItem = styled.div`
  margin: 0 0.1rem;
  min-height: 2rem;
  min-height: calc(2rem + 2px);

  a {
    background-color: #ffff;
    border: 2px black solid;
    cursor: pointer;
    font-size: 0.8em;
    padding: 0.2rem;

    &.active {
      background-color: black;
      color: white;
    }
  }

  @media (min-width: 769px) {
    min-height: 3rem;
    min-height: calc(3rem + 2px);
    margin: 0 0.25rem;
    line-height: 3rem;
    line-height: calc(3rem + 2px);
    white-space: nowrap;

    a {
      font-size: initial;
      padding: 0.5rem;

      :hover {
        background-color: black;
        color: white;
      }
    }
  }
`;
